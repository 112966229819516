export const environment = {
  production: true,
  isLive: false,
  AppName: "Remote Admin Panel",
  multi_API_ENDPOINT: {
    magenative: {
      name: "Magenative",
      API_ENDPOINT: "https://zoho-api-backend.magenative.com/",
      default: true,
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjY4N2EyODU2YjYwYTExMjhmMDAyNTE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjY4YmYyZTJkNzc4MjAzMDhjMDcyNmIyIn0.VfuNHRSo4G8ZYlXTmMy1rzQAQNc9y0apbx99hXVIFSNUqNPvpbMWuvDXxx1xN858_ywNrdHqkiDVgivh1w9ExQk3uaaZIK5KK3LDA7Z7QWlqN6WpXF5ZK9AL0UwmnxxEya2VVsqC38cyb1Mtg3Tv4PSVxBCIQkZp43Kef7GGJptS1tsbET8UqeRVv1TsyPTdj1iPioYe9wqion1EGyOa03CbquFK0sqswfgX5gQiaaIH69OWArDXFOQFzJKl5dLBDj4rbYJmnMMU9jMyRVlQi9CAf8F3TIrMHT4iRgvcT6ZpgJnuK5-AM7M0ETmqk6I8Z6jjYfWOQJKmooU4TAIpyRzR0DYkrDIvBAZZiIYa2MHyxjuh2NTPM_p6noOLb0audzq2Cs_srp1qTAf209InnUpRFYAUJeTIEoEzjGhnvcwTjxxCTx_2GMi2ZWahVoSN-El7sT4bBlwzR8o4VqmCa4eTugMJ6HUnPP4vxghIXgXQLgyeHldZbdsUX77ribaaPBpUWx3Bt0u_uWofPQzbIeM_tzXX4WJyJs8jgInafApVWSux_ZnsgG9k0Gxes2L2z2wka8UNbI-XkKHK_nOsjpc9NscnBFRKJNOk6d6Ofq21h5waMo-W2Mh5V5v0ylWHODfRc-577aWpg5fHsjlkRiRwF1rI_OhontCVqG_JgvY",
    },
  },
};
